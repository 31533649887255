<template>
  <div>
    <AlterFormLayout
      modalId="report-income-modal"
      formTitle="Visualização do informe de rendimentos"
      cancelButtonText="Fechar"
      submitButtonText="Baixar informe de rendimentos em PDF"
      :onSubmitEvent="downloadPdf"
      :disableValidation="true"
    >
      <template #content>
        <pdf-viewer :getDocumentHandler="getDocumentHandler" />
      </template>
    </AlterFormLayout>
  </div>
</template>

<script>
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  props: ["competencyYear"],
  data() {
    return {
      downloadedFile: null,
      fileUrl: null,
    };
  },
  components: {
    AlterFormLayout,
    PdfViewer,
  },
  methods: {
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "informe-rendimentos.pdf",
        this.downloadedFile
      );
    },
    async getDocumentHandler() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      return result;
    },
    async downloadFile() {
      const response = await downloadBase64File(
        this.$store.getters.getUserName,
        "INCOME_REPORT",
        this.$props.competencyYear
      );

      this.fileUrl = response.fileUrl;
      return response.document;
    },
  },
};
</script>
