<template>
  <b-container class="view-container" fluid>
    <b-row class="justify-content-center my-2">
      <p class="p4">
        Para ter acesso ao seu Informe de Rendimentos, basta selecionar o ano de
        competência e clicar em “Ver informe de rendimentos”.
      </p>
      <p>
        Observações: 1 - Informe de rendimentos disponível somente para
        cooperados que recebem por pessoa física. 2 - Cooperados que recebem por
        pessoa jurídica deverão buscar o Informe de rendimentos diretamente com
        a PJ por onde recebem os pagamentos.
      </p>
      <b-card class="custom-card flex-grow-1 mt-4 mb-4">
        <div v-show="!this.loading">
          <div
            v-if="!competencyYearsOptions || competencyYearsOptions.length <= 0"
            class="text-center"
          >
            <a>Nenhuma competência encontrada!</a>
          </div>

          <b-row v-else class="flex-center align-items-center">
            <b-col>
              ANO DE COMPETÊNCIA
            </b-col>
            <b-col>
              <b-form-select
                v-model="selectedOption"
                :options="competencyYearsOptions"
                disabled-field="notEnabled"
                size="lg"
              />
            </b-col>
          </b-row>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="1" :columns="1"></b-skeleton-table>
        </div>
      </b-card>
    </b-row>

    <b-row class="mt-auto">
      <b-button
        class="m-3"
        variant="primary"
        v-b-modal:report-income-modal
        v-show="
          !this.loading &&
            competencyYearsOptions &&
            competencyYearsOptions.length > 0
        "
      >
        Ver informe de rendimentos
      </b-button>
    </b-row>

    <report-income-modal :competencyYear="selectedOption" />

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import ReportIncomeModal from "../modal/ReportIncomeModal.vue";
import { getCompetencyYears } from "../../../../services/competency-years/competency-years-services";
import { DocumentType } from "../../../../static-data/DocumentType.js";

export default {
  name: "report-income-tab",
  components: {
    SuccessModal,
    ReportIncomeModal,
  },
  data() {
    return {
      selectedOption: new Date().getFullYear(),
      selectOptions: [],
      competencyYearsOptions: [],
      loading: true,
    };
  },
  mounted() {
    this.getCompetenceYears();
  },
  methods: {
    async getCompetenceYears() {
      try {
        this.loading = true;
        const crm = this.$store.getters.getUserName;

        const competencyOptions = await getCompetencyYears(
          crm,
          DocumentType.INCOME_REPORT
        );
        this.competencyYearsOptions = competencyOptions
          .map((c) => c.year)
          .sort((a, b) => b - a);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error on function getCompetenceYears: ", error);
      }
    },
  },
};
</script>

<style lang="scss">
.view-container.container-fluid {
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  height: 60vh;
  text-align: left;
}
</style>
